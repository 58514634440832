<form
  class="card"
  fxLayout="column"
  fxLayoutGap="20px"
>
  <span class="title">患者情報の優先度設定</span>
  <div fxLayout="column" fxLayoutGap="20px" *ngIf="loaded">
    <div fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="20px">
      <div class="label">デフォルト値</div>
      <mat-radio-group fxFlex fxLayoutGap="20px" [(ngModel)]="patientPrioritySource" name="patientPrioritySource">
        <mat-radio-button value="dicom">DICOMタグ</mat-radio-button>
        <mat-radio-button value="csv">患者情報CSV</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
      <app-button color="gray" type="button" width="94px" height="25px" [mat-dialog-close]="true"
      >キャンセル</app-button
      >
      <app-button
        type="submit"
        color="primary"
        width="52px"
        height="25px"
        (click)="submit()"
        [disabled]="lastSavedValue === patientPrioritySource"
      >保存</app-button
      >
    </div>
  </div>
</form>
