<form
  class="card"
  fxLayout="column"
  fxLayoutGap="20px"
>
  <span class="title">CSVファイル出力設定</span>
  <div fxLayout="column" fxLayoutGap="20px" *ngIf="loaded">
    <div fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="20px">
      <div class="label">項目名の表示</div>
      <div>
        <mat-slide-toggle  [(ngModel)]="showHeader" name="showHeader"></mat-slide-toggle>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
      <app-button color="gray" type="button" width="94px" height="25px" [mat-dialog-close]="true"
      >キャンセル</app-button
      >
      <app-button
        type="submit"
        color="primary"
        width="52px"
        height="25px"
        (click)="submit()"
        [disabled]="lastSavedValue === showHeader"
      >保存</app-button
      >
    </div>
  </div>
</form>
