import {Component, OnInit} from '@angular/core';
import {Auth0Service, Auth0StoreService} from "@app/core/services";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {SnackBarComponent} from "@app/shared/components";

@Component({
  selector: 'app-import-setting',
  templateUrl: './import-setting.component.html',
  styleUrls: ['./import-setting.component.scss']
})
export class ImportSettingComponent implements OnInit {
  patientPrioritySource : 'dicom' | 'csv' = 'dicom';
  lastSavedValue :'dicom' | 'csv' = 'dicom';
  loaded : boolean = false;
  constructor(
    private auth0Service: Auth0Service,
    private matSnackBar: MatSnackBar,
    private auth0StoreService: Auth0StoreService,
  ) { }

  async ngOnInit() {
    this.loaded = false;
    let currentSettings = await this.auth0Service.getUserSettings();
    this.patientPrioritySource = currentSettings.patientPrioritySource === undefined ? 'dicom' : currentSettings.patientPrioritySource;
    this.lastSavedValue = this.patientPrioritySource;
    this.loaded = true;
  }

  async submit() {
    await this.auth0Service.saveUserSettings({
      patientPrioritySource: this.patientPrioritySource,
    });

    this.matSnackBar.openFromComponent(SnackBarComponent, {
      data: {
        success: true,
        message: '患者情報の優先度設定を保存しました',
      },
      duration: 3 * 1000,
    });

    localStorage.removeItem('fundusApiCache');
    this.lastSavedValue = this.patientPrioritySource;
    await this.auth0StoreService.fetchCurrentUserMeta();
  }
}
