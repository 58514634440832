<div class="container">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title">設定</span>

    <button mat-icon-button (click)="handleCloseDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="main" fxLayout="row" fxLayoutGap="20px">
    <app-setting-sidenav
      [templateList]="templateList"
      [navList]="navList"
      [userRole]="userRole"
      (selectedSetting)="handleSelected($event)"
      (addTemplate)="addTemplate()"
      [selectedMenuItem]="selectedMenuItem"
    ></app-setting-sidenav>
    <ng-container *ngIf="kind === 'template'">
      <app-template-setting
        [template]="selectedTemplate"
        [templateList]="templateList"
        [userRole]="userRole"
        (templateUpdated)="onTemplateSaved($event)"
        (templateAdded)="onTemplateSaved($event)"
        (templateChanged)="onTemplateChanged($event)"
        (editCanceled)="handleCloseDialog('goToDefaultTemplate')"
      ></app-template-setting>
    </ng-container>
    <ng-container *ngIf="kind === 'facility' && selectedFacilityComponent">
      <ng-container *ngComponentOutlet="selectedFacilityComponent"></ng-container>
    </ng-container>
    <ng-container *ngIf="kind === 'user'">
      <app-shortcut-key-setting *ngIf="selectedMenuItem === 'user:shortcut'"></app-shortcut-key-setting>
      <app-display-setting *ngIf="selectedMenuItem === 'user:display'"></app-display-setting>
      <app-report-setting *ngIf="selectedMenuItem === 'user:report'"></app-report-setting>
      <app-import-setting *ngIf="selectedMenuItem === 'user:import'"></app-import-setting>
      <app-user-info-setting *ngIf="selectedMenuItem === 'user:user'"></app-user-info-setting>
    </ng-container>
  </div>
</div>
