import { Component, OnInit } from '@angular/core';
import {Auth0Service, Auth0StoreService} from "@app/core/services";
import {SnackBarComponent} from "@app/shared/components";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Component({
  selector: 'app-report-setting',
  templateUrl: './report-setting.component.html',
  styleUrls: ['./report-setting.component.scss']
})
export class ReportSettingComponent implements OnInit {
  showHeader : boolean = true;
  lastSavedValue : boolean = true;
  loaded : boolean = false;
  constructor(
    private auth0Service: Auth0Service,
    private matSnackBar: MatSnackBar,
    private auth0StoreService: Auth0StoreService,
  ) { }

  async ngOnInit() {
    this.loaded = false;
    let currentSettings = await this.auth0Service.getUserSettings();
    this.showHeader = currentSettings.showHeader === undefined ? true : currentSettings.showHeader;
    this.lastSavedValue = this.showHeader;
    this.loaded = true;
  }

  async submit() {
    await this.auth0Service.saveUserSettings({
      showHeader: this.showHeader,
    });

    this.matSnackBar.openFromComponent(SnackBarComponent, {
      data: {
        success: true,
        message: 'CSVファイル出力設定を保存しました',
      },
      duration: 3 * 1000,
    });

    localStorage.removeItem('fundusApiCache');
    this.lastSavedValue = this.showHeader;
    await this.auth0StoreService.fetchCurrentUserMeta();
  }
}
